<template>
  <usage
    hide-id
    :data="data"
    prefix="Team/Members"
  />
</template>

<script>
// Import the common usage table component
import Usage from "@/components/admin/common/Usage.vue"

// Export the SFC
export default {
  // Name of the component
  name: "TeamUsageIndex",

  // Register the components
  components: {
    Usage
  },

  // Define local readonly variables
  computed: {
    /**
     * Get the data from store
     *
     * @returns {Null|Array}
     */
    data() {
      return this.$store.getters["team/creditUsageResponse"]
    }
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // If we don't have the API data
    if (!this.data) {
      // Dispatch the action to get the data
      this.$store.dispatch("team/fetchCreditUsage")
    }
  }
}
</script>
